<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>免责声明</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="foot-detail">
      <h3>免责声明</h3>
      <p>
        用户在接受人民交通出版社股份有限公司的服务之前，请务必仔细阅读本条款并同意本声明。
      </p>

      <p>
        用户直接或通过各类方式（如站外API引用等）间接使用人民交通出版社股份有限公司服务和数据的行为，都将被视作已无条件接受本声明所涉全部内容；若用户对本声明的任何条款有异议，请停止使用人民交通出版社股份有限公司所提供的全部服务。
      </p>

      <p>
        一、
        无论用户以何种方式使用人民交通出版社股份有限公司的服务和数据（包括但不限于发表、宣传介绍、转载、浏览及利用人民交通出版社股份有限公司发布内容），不得以任何方式利用人民交通出版社股份有限公司直接或间接从事违反中国法律、以及社会公德的行为，且用户应当恪守下述承诺：
        1. 发布、转载或提供的内容符合中国法律、社会公德； 2.
        不得干扰、损害和侵犯人民交通出版社股份有限公司的各种合法权利与利益； 3.
        遵守人民交通出版社股份有限公司以及与之相关的网络服务的协议、指导原则、管理细则等；
        人民交通出版社股份有限公司有权对违反上述承诺的内容予以删除。
      </p>

      <p>
        二、人民交通出版社股份有限公司仅为学员发布的内容提供存储空间，人民交通出版社股份有限公司不对用户发表、转载的内容提供任何形式的保证：不保证内容满足您的要求，不保证人民交通出版社股份有限公司的服务不会中断。因网络状况、通讯线路、第三方网站或管理部门的要求等任何原因而导致您不能正常使用，人民交通出版社股份有限公司不承担任何法律责任。
      </p>

      <p>
        三、学员在人民交通出版社股份有限公司发表的内容（包含但不限于人民交通出版社股份有限公司目前各产品功能里的内容）仅表明其个人的立场和观点，并不代表人民交通出版社股份有限公司的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。人民交通出版社股份有限公司不承担任何法律及连带责任。
      </p>

      <p>
        四、学员在人民交通出版社股份有限公司发布侵犯他人知识产权或其他合法权益的内容，人民交通出版社股份有限公司有权予以删除，并保留移交司法机关处理的权利。
      </p>

      <p>
        五、个人或单位如认为人民交通出版社股份有限公司上存在侵犯自身合法权益的内容，应准备好具有法律效应的证明材料，及时与人民交通出版社股份有限公司取得联系，以便人民交通出版社股份有限公司迅速做出处理。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home'
}
</script>
